window.KTLogin = function() {
  var _login;
  var _showForm = function(form) {
    var cls = 'login-' + form + '-on';
    var form = 'kt_login_' + form + '_form';

    _login.removeClass('login-forgot-on');
    _login.removeClass('login-signin-on');
    _login.removeClass('login-signup-on');

    _login.addClass(cls);

    KTUtil.animateClass(KTUtil.getById(form), 'animate__animated animate__backInUp');
  }

  var _buttonSpinnerClasses = 'spinner spinner-right spinner-white pr-15';

  var _handleFormSignin = function() {
    var sign_in_form = KTUtil.getById('kt_login_signin_form');
    var formSubmitUrl = KTUtil.attr(sign_in_form, 'action');
    var formSubmitButton = KTUtil.getById('kt_login_signin_submit');
    var validation;


    validation = FormValidation.formValidation(
      sign_in_form, {
        fields: {
          'user[email]': {
            validators: {
              notEmpty: {
                message: 'Email adress is required'
              },

              callback: {
                  message: 'The value is not a valid email address',
                  callback: function(input) {
                      const value = input.value;
                      if (value === '') {
                          return true;
                      }

                      // I want the value has to pass both emailAddress and regexp validators
                      return FormValidation.validators.emailAddress().validate({
                          value: value,
                      }).valid &&
                      FormValidation.validators.regexp().validate({
                          value: value,
                          options: {
                              regexp: /^[^@\s]+@([^@\s]+\.)+[^@\s]+$/,
                          },
                      }).valid;
                  },
              }
            }
          },
          'user[password]': {
            validators: {
              notEmpty: {
                message: 'Password is required'
              }
            }
          }
        },
        plugins: {
          trigger: new FormValidation.plugins.Trigger(),
          // submitButton: new FormValidation.plugins.SubmitButton(),
          //defaultSubmit: new FormValidation.plugins.DefaultSubmit(), // Uncomment this line to enable normal button submit after form validation
          bootstrap: new FormValidation.plugins.Bootstrap({
            //  eleInvalidClass: '', // Repace with uncomment to hide bootstrap validation icons
            //  eleValidClass: '',   // Repace with uncomment to hide bootstrap validation icons
          })
        }
      }
    );


    sign_in_form.addEventListener('ajax:beforeSend', function(event){
      var xhr = event.detail[0]
      var option = event.detail[1]

      KTUtil.btnWait(formSubmitButton, _buttonSpinnerClasses);

      validation.validate().then(function(status) {
        if (status != 'Valid') {
          xhr.abort();
          Rails.stopEverything(event)
          swal.fire({
            text: "Sorry, looks like there are some errors detected, please try again.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn font-weight-bold btn-light-primary"
            }
          }).then(function() {
            KTUtil.scrollTop();
          });

          setTimeout(function() {
            $(formSubmitButton).removeClass(_buttonSpinnerClasses);
          }, 600);

          formSubmitButton.innerText = "Sign in";
        }
      });
    });



    // Handle forgot button
    $('#kt_login_forgot').on('click', function(e) {
      e.preventDefault();
      _showForm('forgot');
    });
  }

  var _handleFormForgot = function() {
    var form = KTUtil.getById('kt_login_forgot_form');
    var formSubmitUrl = KTUtil.attr(form, 'action');
    var formSubmitButton = KTUtil.getById('kt_login_forgot_form_submit_button');

    var validation = FormValidation.formValidation(
      form, {
        fields: {
          'user[email]': {
            validators: {
              notEmpty: {
                message: 'Email is required'
              },
              emailAddress: {
                message: 'The value is not a valid email address'
              },
            }
          }
        },
        plugins: {
          trigger: new FormValidation.plugins.Trigger(),
          // submitButton: new FormValidation.plugins.SubmitButton(),
          //defaultSubmit: new FormValidation.plugins.DefaultSubmit(), // Uncomment this line to enable normal button submit after form validation
          bootstrap: new FormValidation.plugins.Bootstrap({
            //  eleInvalidClass: '', // Repace with uncomment to hide bootstrap validation icons
            //  eleValidClass: '',   // Repace with uncomment to hide bootstrap validation icons
          })
        }
      }
    ).on('core.validator.validating', function(){
      $('div[data-validator="emailPresence"]').remove()
    });

    form.addEventListener('ajax:beforeSend', (event) => {
      // [xhr, options] = event.detail;
      var xhr = event.detail[0]
      var option = event.detail[1]

      validation.validate().then(function(status) {
        if (status != 'Valid') {
          xhr.abort();
          Rails.stopEverything(event)

          Swal.fire({
            text: "Sorry, looks like there are some errors detected, please try again.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn font-weight-bold btn-light-primary"
            }
          }).then(function() {
            KTUtil.scrollTop();
          });
        } else {
          KTUtil.btnWait(formSubmitButton, _buttonSpinnerClasses, "Please wait");

          // Simulate Ajax request
          setTimeout(function() {
            KTUtil.btnRelease(formSubmitButton);
          }, 2000);
        }
      });
    });


    $('#kt_login_forgot_cancel').on('click', function(e) {
      e.preventDefault();
      $('#forgot_user_email').val('')
      validation.resetForm(true)
      $('div[data-validator="emailPresence"]').remove()
      $('#kt_login_forgot_submit').text('Request')
      _showForm('signin');
    });
  }




  // Public Functions
  return {
    init: function() {
      _login = $('#kt_login');
      _handleFormSignin();
      _handleFormForgot();
    }
  };
}();


$(document).ready(function() {
  KTLogin.init();
});
